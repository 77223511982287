
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { Spin, Card, Row, Col, Button } from 'antd';
import FileSaver from 'file-saver';
import domtoimage from 'dom-to-image';
import { formatNumber, awaitWrapper } from '@utils/helper';
import './style.less';

const PRODUCTS = [
  { code: 'DLTTSN', salePrice: 768000, salePercent: 20 },
  { code: 'DLTTMDT', salePrice: 768000, salePercent: 20 },
  { code: 'DLTTSNV', salePrice: 768000, salePercent: 20 },
  { code: 'DLTTSCNX', salePrice: 768000, salePercent: 20 },
  { code: 'DLTTCNC', salePrice: 768000, salePercent: 20 },
  { code: 'DTMHTX', salePrice: 736000, salePercent: 20 },
  { code: 'DTHND', salePrice: 656000, salePercent: 20 },
  { code: 'DTBV', salePrice: 623000, salePercent: 30 },
  { code: 'DTHXX', salePrice: 644000, salePercent: 30 },
  { code: 'DTVH', salePrice: 574000, salePercent: 30 },
  { code: 'DTMLT', salePrice: 623000, salePercent: 30 },
  { code: 'DTHVDC', salePrice: 574000, salePercent: 30 },
  { code: 'DSBX', salePrice: 553000, salePercent: 30 },
  { code: 'DSND', salePrice: 574000, salePercent: 30 },
  { code: 'DTLKX', salePrice: 553000, salePercent: 30 },
  { code: 'DLTTHV', salePrice: 672000, salePercent: 30 },
  { code: 'DLHX', salePrice: 574000, salePercent: 30 },
  { code: 'DLAT', salePrice: 623000, salePercent: 30 },
  { code: 'DTMML', salePrice: 672000, salePercent: 30 },
  { code: 'DTCTX', salePrice: 574000, salePercent: 30 },
  { code: 'DTBH', salePrice: 623000, salePercent: 30 },
  { code: 'DTBXC', salePrice: 644000, salePercent: 30 },
  { code: 'DTTND', salePrice: 574000, salePercent: 30 },
  { code: 'DTHXD', salePrice: 644000, salePercent: 30 },
  { code: 'DTBVSN', salePrice: 623000, salePercent: 30 },
  { code: 'DTXNT', salePrice: 553000, salePercent: 30 },
  { code: 'DTBC', salePrice: 623000, salePercent: 30 },
  { code: 'DHDSH', salePrice: 574000, salePercent: 30 },
  { code: 'DTCMD', salePrice: 602000, salePercent: 30 },
  { code: 'DTSTND', salePrice: 574000, salePercent: 30 },
  { code: 'DLTTTTX', salePrice: 672000, salePercent: 30 },
  { code: 'DLTTCNX', salePrice: 672000, salePercent: 30 },
  { code: 'DTBSNV', salePrice: 595000, salePercent: 30 },
  { code: 'DTBXB', salePrice: 623000, salePercent: 30 },
  { code: 'DTBS', salePrice: 644000, salePercent: 30 },
  { code: 'DHTNX', salePrice: 574000, salePercent: 30 },
  { code: 'DLTTHD', salePrice: 903000, salePercent: 30 },
  { code: 'DTBHO', salePrice: 574000, salePercent: 30 },
  { code: 'DLTTSD', salePrice: 672000, salePercent: 30 },
  { code: 'DLTTMDX', salePrice: 672000, salePercent: 30 },
  { code: 'DLAH', salePrice: 623000, salePercent: 30 },
  { code: 'DLTTHTD', salePrice: 644000, salePercent: 30 },
  { code: 'DTHMH', salePrice: 574000, salePercent: 30 },
  { code: 'DTMDLX', salePrice: 553000, salePercent: 30 },
  { code: 'DLTTSBC', salePrice: 672000, salePercent: 30 },
  { code: 'DHTD', salePrice: 595000, salePercent: 30 },
  { code: 'DHTSX', salePrice: 595000, salePercent: 30 },
  { code: 'DTHHD', salePrice: 574000, salePercent: 30 },
  { code: 'DLTTTTD', salePrice: 672000, salePercent: 30 },
  { code: 'DTOHA', salePrice: 552000, salePercent: 40 },
  { code: 'KL', salePrice: 174000, salePercent: 40 },
  { code: 'DHTMD', salePrice: 510000, salePercent: 40 },
  { code: 'DMDTDO', salePrice: 492000, salePercent: 40 },
  { code: 'DLMDX', salePrice: 492000, salePercent: 40 },
  { code: 'DTOBN', salePrice: 534000, salePercent: 40 },
  { code: 'DLTTMDTNV', salePrice: 576000, salePercent: 40 },
  { code: 'DTMDN', salePrice: 492000, salePercent: 40 },
  { code: 'DCHV', salePrice: 552000, salePercent: 40 },
  { code: 'DHVNN', salePrice: 492000, salePercent: 40 },
  { code: 'DLLHD', salePrice: 492000, salePercent: 40 },
  { code: 'DHTV', salePrice: 510000, salePercent: 40 },
  { code: 'DLLHX', salePrice: 492000, salePercent: 40 },
  { code: 'DMLEN', salePrice: 474000, salePercent: 40 },
  { code: 'DMDTD', salePrice: 474000, salePercent: 40 },
  { code: 'DTOBDO', salePrice: 534000, salePercent: 40 },
  { code: 'DTMDDH', salePrice: 492000, salePercent: 40 },
  { code: 'AKKHND', salePrice: 234000, salePercent: 40 },
  { code: 'DTHNX', salePrice: 492000, salePercent: 40 },
  { code: 'DTNXHH', salePrice: 486000, salePercent: 40 },
  { code: 'DSDND', salePrice: 492000, salePercent: 40 },
  { code: 'DTHNH', salePrice: 492000, salePercent: 40 },
  { code: 'DTBT', salePrice: 534000, salePercent: 40 },
  { code: 'AKTHMH', salePrice: 234000, salePercent: 40 },
  { code: 'AKHL', salePrice: 234000, salePercent: 40 },
  { code: 'DLTTSTX', salePrice: 576000, salePercent: 40 },
  { code: 'DTHTIX', salePrice: 492000, salePercent: 40 },
  { code: 'DLTTLT', salePrice: 576000, salePercent: 40 },
  { code: 'DLTTXHH', salePrice: 576000, salePercent: 40 },
  { code: 'DCDC', salePrice: 492000, salePercent: 40 },
  { code: 'DSTNC', salePrice: 492000, salePercent: 40 },
  { code: 'DLTTNT', salePrice: 576000, salePercent: 40 },
  { code: 'DLTTCPM', salePrice: 576000, salePercent: 40 },
  { code: 'AKHV', salePrice: 234000, salePercent: 40 },
  { code: 'DLTTHLNV', salePrice: 576000, salePercent: 40 },
  { code: 'KLTT', salePrice: 234000, salePercent: 40 },
  { code: 'AKT', salePrice: 234000, salePercent: 40 },
  { code: 'DTHXDC', salePrice: 474000, salePercent: 40 },
  { code: 'DSTCT', salePrice: 375000, salePercent: 50 },
  { code: 'DSXND', salePrice: 410000, salePercent: 50 },
  { code: 'DMDT', salePrice: 375000, salePercent: 50 },
  { code: 'DMDHP', salePrice: 395000, salePercent: 50 },
  { code: 'DTSXNN', salePrice: 410000, salePercent: 50 },
  { code: 'DTIBDN', salePrice: 375000, salePercent: 50 },
  { code: 'DMDDN', salePrice: 395000, salePercent: 50 },
  { code: 'DLTTCX', salePrice: 460000, salePercent: 50 },
  { code: 'DTOCTK', salePrice: 460000, salePercent: 50 },
  { code: 'DXBDN', salePrice: 375000, salePercent: 50 },
  { code: 'DHNT', salePrice: 410000, salePercent: 50 },
  { code: 'DLMDH', salePrice: 410000, salePercent: 50 },
  { code: 'DSXNV', salePrice: 395000, salePercent: 50 },
  { code: 'DSXCT', salePrice: 395000, salePercent: 50 },
  { code: 'DLTTXHNU', salePrice: 480000, salePercent: 50 },
  { code: 'DTSXL', salePrice: 410000, salePercent: 50 },
  { code: 'DLTTLS', salePrice: 480000, salePercent: 50 },
  { code: 'DLTTBCA', salePrice: 480000, salePercent: 50 },
  { code: 'DLTTHDV', salePrice: 460000, salePercent: 50 },
  { code: 'DLTTTHT', salePrice: 480000, salePercent: 50 },
  { code: 'DLTTCS', salePrice: 480000, salePercent: 50 },
  { code: 'DLTTVHS', salePrice: 480000, salePercent: 50 },
  { code: 'DLTTNK', salePrice: 480000, salePercent: 50 },
  { code: 'DHDEB', salePrice: 138000, salePercent: 70 },
  { code: 'DHHCEB', salePrice: 138000, salePercent: 70 },
  { code: 'DHX', salePrice: 237000, salePercent: 70 },
  { code: 'DHOTT', salePrice: 225000, salePercent: 70 },
  { code: 'ADD', salePrice: 225000, salePercent: 70 },
  { code: 'DVHSEB', salePrice: 138000, salePercent: 70 },
  { code: 'DTBD', salePrice: 225000, salePercent: 70 },
  { code: 'DTOHO', salePrice: 246000, salePercent: 70 },
  { code: 'DTOHTD', salePrice: 255000, salePercent: 70 },
  { code: 'DLMHH', salePrice: 267000, salePercent: 70 },
  { code: 'DTCHNXD', salePrice: 246000, salePercent: 70 },
  { code: 'DXBD', salePrice: 225000, salePercent: 70 },
  { code: 'DTIBD', salePrice: 225000, salePercent: 70 },
  { code: 'DLTET', salePrice: 267000, salePercent: 70 },
  { code: 'DTMDXT', salePrice: 246000, salePercent: 70 },
  { code: 'DTTN', salePrice: 267000, salePercent: 70 },
  { code: 'DVBD', salePrice: 225000, salePercent: 70 },
  { code: 'DTTDBD', salePrice: 225000, salePercent: 70 },
  { code: 'DLTIH', salePrice: 237000, salePercent: 70 },
  { code: 'DMLEB', salePrice: 138000, salePercent: 70 },
]


function Campaign(props) {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [showBanner, setShowBanner] = useState(false);
  const [mainPageIndex, setMainPageIndex] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  let tDoDownloadAll = null;
  const { isLoading, mutate: getProductList } = useMutation(() => {
    return request.get('v1/product-meta');
  },
    {
      onSuccess: (response) => {
        setProductList(response.data);
        initData(response.data);
      },
    });
  useEffect(() => {
    getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initData = (productList) => {
    const data = [];
    PRODUCTS.forEach(p => {
      const sku = p.code.toLowerCase();
      const item = productList.find(x => x.pSku === sku);
      if (item) {
        // const salePercent = 100 - Math.round(p.salePrice / item.price * 100);
        // if(salePercent >= 40){
        const newItem = {
          ...item,
          salePrice: p.salePrice,
          salePercent: p.salePercent,
        }
        if(!item.image && p.url){
          newItem.image = p.url;
        }
        data.push(newItem);
        // }
      }
    })
    setDataSource(data);
  }
  const updateWoo = async (product = {}, resetPrice = false) => {
    if (!product.wooProductId) return false;
    const getVariationData = {
      url: `products/${product.wooProductId}`,
      method: 'get'
    }
    const [gErr, gRes] = await awaitWrapper(request.post('/v1/wp-woo', getVariationData));
    if (!gErr) {
      const wooProduct = gRes.data;
      const variations = wooProduct.variations || [];
      for (let i = 0; i < variations.length; i++) {
        const data = {
          url: `products/${product.wooProductId}/variations/${variations[i]}`,
          method: 'put',
          data: {
            sale_price: resetPrice ? `${product.price}` : `${product.salePrice}`,
          }
        }
        await awaitWrapper(request.post('/v1/wp-woo', data));
      }
    }
  }

  const handleUpdate = async (resetPrice = false) => {
    setLoading(true);
    const pList = [];
    PRODUCTS.forEach(p => {
      const sku = p.code.toLowerCase();
      const item = productList.find(x => x.pSku === sku);
      if (item) {
        pList.push({
          ...item,
          salePrice: p.salePrice
        })
      }
    })
    for (let i = 0; i < pList.length; i++) {
      await updateWoo(pList[i], resetPrice);
    }
    setLoading(false);
  }
  const handleImageLoaded = () => {
    if (isDownloading) {
      handleDownload();
      clearTimeout(tDoDownloadAll);
      const nextTick = Math.round(Math.random() * 1000 + 1000);
      tDoDownloadAll = setTimeout(() => { doDownloadAll(); }, nextTick);
    }
    console.log('image loaded');
  }
  const handleDownloadAll = async () => {
    if (isDownloading) {
      setIsDownloading(false);
    } else {
      setIsDownloading(true);
      setMainPageIndex(67);
      doDownloadAll();
    }
  }
  const doDownloadAll = async () => {
    if (mainPageIndex >= PRODUCTS.length) {
      setIsDownloading(false);
      return;
    }
    if (mainPageIndex < PRODUCTS.length - 1) {
      let ind = mainPageIndex + 1;
      const p = PRODUCTS[ind];
      if (!p) ind += 1;
      else {
        const sku = p.code.toLowerCase();
        const item = productList.find(x => x.pSku === sku);
        if (!item.image) {
          ind += 1;
        }
      }

      setMainPageIndex(ind);
    } else {
      setIsDownloading(false);
    }
    return;
  }
  const handleDownload = async () => {
    var node = document.querySelector('.productBanner');
    const name = node.getAttribute('data-name');
    // const name = 'TEST';
    domtoimage.toBlob(node)
      .then(function (blob) {
        FileSaver.saveAs(blob, `${name}.png`);
      });
  }
  const handleShowBanner = () => {
    if (!showBanner) {
      setMainPageIndex(0);
    }
    setShowBanner(!showBanner);
  }
  const handleClickItem = (itemInd => {
    setMainPageIndex(itemInd);
    if (!showBanner) setShowBanner(true);
  })
  const renderProductDetail = (item, ind) => {
    // const sku = p.code.toLowerCase();
    // const item = productList.find(x => x.pSku === sku);
    // if (!item) return '';
    return (
      <Col sm={6} style={{ marginBottom: 16 }} key={item.pSku}>
        <Card hoverable cover={<img height={400} width="auto" alt={item.pName} src={item.image} />} onClick={() => handleClickItem(ind)}>
          <div style={{ height: 42 }}>D{ind + 1} - {item.pName} - {item.pSku}</div>
          <div style={{ fontSize: 20 }}>{formatNumber(item.price)}{` => `}<b>{formatNumber(item.salePrice)}</b></div>
        </Card>
      </Col>
    )
  }
  const renderProductBanner = () => {
    if (mainPageIndex < 0) return '';
    // const p = PRODUCTS[mainPageIndex];
    // if (!p) return '';
    // const sku = p.code.toLowerCase();
    // const item = productList.find(x => x.pSku === sku);
    const item = dataSource[mainPageIndex];
    if (!item) return '';
    const orgPrice = item.price / 1000;
    const salePrice = item.salePrice / 1000;
    const imageSrc = item.image ? item.image.replace('https://otina.n4u.vn/', 'https://otina-proxy.n4u.vn/') : '';
    let minorImage = imageSrc;
    if (item.gallery && item.gallery.indexOf(',') > 0) {
      const galleries = item.gallery.split(',');
      minorImage = galleries[0].replace('https://otina.n4u.vn/', 'https://otina-proxy.n4u.vn/');
    }
    const sku = item.pSku.toLowerCase();
    const name = item.pName;
    return (
      <div className='productBanner' data-percent={item.salePercent} data-name={`${item.salePercent}-${salePrice}-20240825-${sku.toUpperCase()}`}>
        <div className='imageFrame' style={{ backgroundImage: `url(${imageSrc})` }}></div>
        <img className='imageSrc' alt="MainImage" src={imageSrc} onLoad={handleImageLoaded} />
        <div className='priceArea'>
          <div className='price'>
            <span className='orgPrice'>{`${orgPrice}K`}</span>
            <span className='salePrice'>{salePrice}K</span>
          </div>
          <div className='minorImage' style={{ backgroundImage: `url(${minorImage})` }}></div>
        </div>
        <div className='sku'>{sku.toUpperCase()}</div>
        <div className='salePercent'>{item.salePercent}% OFF</div>
        <div className='name'>{name}</div>
        <div className='code'>D{mainPageIndex + 1}</div>
      </div>
    )
  }
  return (
    <Spin spinning={isLoading}>
      <Row gutter={16}>
        <Col sm={12}>
          <h2>Sale 20241110 - 20241130</h2>
        </Col>
        <Col sm={6}>
          <Button onClick={handleShowBanner}>{showBanner ? 'Hide' : 'Show'}</Button>
          {showBanner &&
            <Button onClick={handleDownloadAll}>{isDownloading ? `Downloading (${mainPageIndex + 1}/${PRODUCTS.length})` : `Download All`}</Button>
          }
        </Col>
        <Col sm={6}>
          <Button loading={loading} disabled={loading} onClick={() => handleUpdate(false)}>Update Price</Button>
          <Button loading={loading} disabled={loading} onClick={() => handleUpdate(true)}>Reset Price</Button>
        </Col>
      </Row>
      {showBanner && (<Row gutter={16}>
        <Col sm={2} align="right">
          <Button style={{ marginTop: 20 }} disabled={mainPageIndex < 1} onClick={() => setMainPageIndex(mainPageIndex - 1)}>Previous</Button>
          <Button onClick={handleDownload}>Download</Button>
        </Col>
        <Col sm={20}>
          <div>
            {renderProductBanner()}
          </div>
        </Col>
        <Col sm={2}>
          <Button style={{ marginTop: 20 }} disabled={mainPageIndex > PRODUCTS.length - 2} onClick={() => setMainPageIndex(mainPageIndex + 1)}>Next</Button>
        </Col>
      </Row>)}
      <Row gutter={16}>
        {dataSource.map(renderProductDetail)}
      </Row>
    </Spin>
  )
}

Campaign.propTypes = {
  dataSource: PropTypes.array,
  categoryList: PropTypes.array,
  loading: PropTypes.bool,
};

Campaign.defaultProps = {
  dataSource: [],
  categoryList: [],
  loading: false,
};

export default Campaign;
