import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Row, Col, Table, Button, List, Tooltip, Checkbox } from 'antd';
import { getImageUrl, searchText } from '@utils/helper';
import { PLACE_LIST } from '@utils/constants';
import { makeSelectUser } from '@/global.selectors';

import { selectList, selectLoading, selectFilter } from '../selectors';
import { onSelectProductAction } from '../actions';
import { COLUMN, SIZE_COLUMN, FULL_SIZE_COLUMN, PRODUCE_STATUS_LIST } from '../constants';
import Filter from './Filter';
import UpdateWoo from '../UpdateWoo';

class ProductMetaList extends PureComponent {
  constructor() {
    super();
    this.state = {
      showGallery: false
    }
  }
  handleChangeShowGallery = e => {
    this.setState({
      showGallery: e.target.checked
    })
  }
  renderName = (_, record) => {
    let deliveryDateString = '';
    if(record.deliveryDate){
      let status = '';
      const stat = PRODUCE_STATUS_LIST.find(x => x.value === record.produceStatus);
      if(stat){
        status = ` (${stat.label})`;
      }
      deliveryDateString = <p><b><i>Dự kiến có hàng: {record.deliveryDate.format('DD/MM/YYYY')}</i></b>{status}</p>
    }
    return (<>
      <h4>{record.id ? `${record.id} - ` : ''}{record.pName}{record.sku ? ` - ${record.sku}` : ''}</h4>
      <p>{record.price} - {record.material} - {record.length ? `${record.length}cm` : ''}</p>
      {deliveryDateString}
      {(this.props.user.role < 2 && record.wooProductId) && <p><a target="_blank" rel="noopener noreferrer" href={`https://otina.n4u.vn/wp-admin/post.php?post=${record.wooProductId}&action=edit`}>{record.wooProductId}</a></p>}
    </>)
  }
  renderMeta = (_, record) => {
    if (!record.metaInfo) return '';
    const parsedObj = record.metaInfo;
    const columns = [
      { title: 'Size', dataIndex: 'size' },
    ]
    COLUMN.forEach(col => {
      columns.push({
        title: col.label,
        dataIndex: col.value
      })
    });
    const dataSource = SIZE_COLUMN.map(size => {
      return {
        size,
        ...parsedObj[size]
      }
    })

    return <Table
      className='metaTable'
      bordered={false}
      pagination={false}
      size="small"
      dataSource={dataSource}
      columns={columns}
      rowKey="size" />
  }
  renderMainProps = (_, record) => {
    return (<>
      <p>Túi: {record.pocket}</p>
      <p>Xẻ: {record.sideSlit}</p>
      <p>Dài lót: {record.underlayLength}</p>
      <p>Sau lưng: {record.back}</p>
    </>)
  }
  renderGallery = text => {
    if (!text) return '';
    const images = text.split(',');
    return (<List dataSource={images} grid={{ gutter: 0, sm: 4 }} renderItem={img => <List.Item><img alt={img} height="60" src={getImageUrl(img)} /></List.Item>} />)
  }
  renderImage = (imageSrc, name, height = 100) => {
    return <img alt={name} style={{ maxHeight: height, maxWidth: 200 }} src={getImageUrl(imageSrc)} />;
  }
  render() {
    const { list, loading, onSelectProduct, filter } = this.props;
    const columns = [
      {
        title: 'Hình',
        dataIndex: 'image',
        width: 120,
        render: (text, record) => text ? <Tooltip title={this.renderImage(text, record.pName, 400)}>{this.renderImage(text, record.pName, 100)}</Tooltip> : '',// <img alt={record.pName} height="100" src={getImageUrl(text)} /> : ''
      },
      {
        title: 'Gallery',
        dataIndex: 'gallery',
        width: 240,
        render: this.renderGallery
      },
      {
        title: 'Tên SP',
        dataIndex: 'pName',
        render: this.renderName,
      },
      {
        title: 'Thông số',
        dataIndex: 'pocket',
        render: this.renderMainProps
      },
      {
        title: 'Chi tiết',
        dataIndex: 'metaInfo',
        render: this.renderMeta
      },
      {
        title: '',
        dataIndex: 'pId',
        render: (_, record) => (
          <>
            <Button icon="edit" onClick={() => {
                onSelectProduct(record);
              }} />
              <br/>
            {record.wooProductId && <UpdateWoo singleMode={true} item={record} />}
          </>
        )

      },
    ];
    FULL_SIZE_COLUMN.forEach(size => {
      columns.push({
        title: `${size.replace('size', '')}`,
        dataIndex: size,
        width: 105,
        sorter: (a, b) => a[size] - b[size],
        render: (text, record) => (<>
          <div>Tổng: <b>{text}</b></div>
          {PLACE_LIST.map(branch => (<div id={branch.value}>
            {branch.label}: <b>{record[`branch${branch.value}${size}`]}</b> <br />
          </div>))}
        </>)
      })
    });
    if (!this.state.showGallery) {
      columns.splice(1, 1);
    }
    let dataSource = list;
    if (filter.id) {
      const fId = parseInt(filter.id, 0);
      dataSource = list.filter(x => x.id === fId);
    }
    else if (filter.name) {
      dataSource = list.filter(x => {
        let result = true;
        result = searchText(x.pName, filter.name) || searchText(x.pSku, filter.name)
        return result;
      })
    }
    return (
      <>
        <Row gutter={16}>
          <Col sm={12}>
            <h2>Sản phẩm</h2>
          </Col>
          <Col sm={6}><UpdateWoo /></Col>
          <Col sm={6}>
            <Checkbox checked={this.state.showGallery} onChange={this.handleChangeShowGallery}>Hiện gallery</Checkbox>
          </Col>
        </Row>
        <Filter />
        <Row gutter={16}>
          <Col sm={24}>
            <Table
              pagination={{ showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50', '100'] }}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              rowKey="uid"
              className='smallTable'
            />
          </Col>
        </Row>
      </>
    );
  }
}

ProductMetaList.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
};

ProductMetaList.defaultProps = {
  list: [],
  loading: false,
};

const mapStateToProps = createStructuredSelector({
  list: selectList(),
  loading: selectLoading(),
  filter: selectFilter(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
  onSelectProduct: (data) => dispatch(onSelectProductAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ProductMetaList);
