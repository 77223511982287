import React, { memo, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Button, Modal, Spin, Progress, Row, Col, Table } from 'antd';
import { makeSelectUser } from '@/global.selectors';
import request from '@utils/request';
import { selectList, selectLoading } from '../selectors';
import { getProductAction } from '../actions';
import { SIZE_COLUMN, PLACE } from '@utils/constants';

const {Column} = Table;

class UpdateWoo extends PureComponent {
  constructor() {
    super();
    this.state = {
      showModal: false,
      isProcessing: false,
      dataList: [],
      currentProcessIndex: 0,
    }
    this.currentProcessIndex = 0;
    this.tDoUpdateWoo = null;
  }
  handleOpenModal = () => {
    this.setState({ showModal: true });
    if(!this.props.singleMode){
      this.props.getList();
    } 
  }
  handleUpdateWoo = () => {
    if (this.state.isProcessing) {
      this.setState({ isProcessing: false });
    } else {
      this.setState({ isProcessing: true });
      this.currentProcessIndex = 0;
      const dataList = this.props.list.filter(x => x.wooProductId);
      this.setState({ dataList, currentProcessIndex: 0 }, () => this.doUpdateWoo());
    }

  }
  doUpdateWoo = async () => {
    if (!this.state.isProcessing || this.currentProcessIndex >= this.state.dataList.length) {
      this.setState({ isProcessing: false });
      return;
    }
    const item = this.getCurrentItem();// this.state.dataList[this.currentProcessIndex];
    const { wooVariationIds } = item;
    const wooProductId = parseInt(item.wooProductId, 0);
    const pushData = [];
    SIZE_COLUMN.forEach((size, ind) => {
      const variationId = wooVariationIds[size] || wooProductId + ind + 1;
      if (variationId) {
        let totalQty = item[`branch${PLACE.OFFICE}${size}`] || 0;
        // if (totalQty) {
        //   const branchHVHQty = item[`branch${PLACE.SHOP48}${size}`];
        //   const branchNDCQty = item[`branch${PLACE.SHOP222}${size}`];
        //   if (branchHVHQty) totalQty -= 1;
        //   if (branchNDCQty) totalQty -= 1;
        // }
        const updateData = {
          id: variationId,
          quantity: totalQty
        }
        pushData.push(updateData);
      }
    });
    if (pushData.length) {
      const putData = {
        productId: wooProductId,
        variations: pushData
      }
      await request.put(`v1/product-meta/woo`, putData);
    }
    clearTimeout(this.tDoUpdateWoo);
    this.currentProcessIndex += 1;
    if (!this.props.singleMode && this.currentProcessIndex < this.state.dataList.length) {
      this.tDoUpdateWoo = setTimeout(() => { this.doUpdateWoo(); }, 500);
      this.setState({ currentProcessIndex: this.currentProcessIndex });
    } else {
      this.setState({
        isProcessing: false
      });
    }

    return;
  }
  getSize = (item) => {
    const result = {};
    SIZE_COLUMN.forEach((size, ind) => {
      result[size] = item[`branch${PLACE.OFFICE}${size}`] || 0;
    });
    return result;
  }
  getCurrentItem = () => {
    if(this.props.singleMode) return this.props.item;
    return this.state.dataList[this.state.currentProcessIndex] || {};
  }
  render() {
    if (this.props.user.role > 1) return '';
    const { showModal, isProcessing } = this.state;
    const { loading } = this.props;
    const currentItem = this.getCurrentItem();
    const progress = isProcessing ? Math.round(((this.state.currentProcessIndex + 1) / this.state.dataList.length) * 100) : 0;
    const currentQty = this.getSize(currentItem);
    const title = `Cập nhật web: ${currentItem.pName}`;
    return (
      <>
        {this.props.singleMode ? <Button title="Update Web" icon="cloud-upload" onClick={this.handleOpenModal} /> :<Button onClick={this.handleOpenModal}>Cập nhật Web</Button>}
        {showModal &&
          <Modal width="40%" title={title} visible onCancel={() => this.setState({ showModal: false })} footer={null}>
            <Spin spinning={loading}>
              <Row>
                <Col sm={24}>
                  <Progress percent={progress} />
                </Col>
              </Row>
              <Row>
                <Col sm={24}>
                  <Button disabled={this.props.loading} onClick={this.handleUpdateWoo} type="primary">{isProcessing ? 'Stop' : 'Start'}</Button>
                </Col>
              </Row>
              {isProcessing && <Row>
                <Col sm={12}>Updating: {currentItem.pName}</Col>
                {SIZE_COLUMN.map(size => (
                  <Col sm={3}>{`${size}: ${currentQty[size]}`}</Col>
                ))}
              </Row>}
            </Spin>
          </Modal>}
      </>
    );
  }
}

UpdateWoo.propTypes = {
  list: PropTypes.array,
  loading: PropTypes.bool,
  singleMode: PropTypes.bool,
  item: PropTypes.object
};

UpdateWoo.defaultProps = {
  list: [],
  loading: false,
  singleMode: false,
  item: {},
};

const mapStateToProps = createStructuredSelector({
  list: selectList(),
  loading: selectLoading(),
  user: makeSelectUser(),
});

const mapDispatchToProps = dispatch => ({
  getList: () => dispatch(getProductAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(UpdateWoo);
