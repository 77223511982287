
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import request from '@utils/request';
import { Spin, Card, Row, Col, Button } from 'antd';
import FileSaver from 'file-saver';
import domtoimage from 'dom-to-image';
import { formatNumber } from '@utils/helper';
import './style.less';

const PRODUCTS = [
  { code: 'DLLHX', salePrice: 574000, salePercent: 30 },
{ code: 'DTTND', salePrice: 574000, salePercent: 30 },
{ code: 'DTBXC', salePrice: 644000, salePercent: 30 },
{ code: 'DLHX', salePrice: 574000, salePercent: 30 },
{ code: 'DHDSH', salePrice: 574000, salePercent: 30 },
{ code: 'DLMDX', salePrice: 574000, salePercent: 30 },
{ code: 'DLTTMDTNV', salePrice: 574000, salePercent: 30 },
{ code: 'DTMHTX', salePrice: 623000, salePercent: 30 },
{ code: 'DHTNX', salePrice: 574000, salePercent: 30 },
{ code: 'DLAT', salePrice: 623000, salePercent: 30 },
{ code: 'DCHV', salePrice: 644000, salePercent: 30 },
{ code: 'DTBH', salePrice: 623000, salePercent: 30 },
{ code: 'DTSTND', salePrice: 574000, salePercent: 30 },
{ code: 'DHNT', salePrice: 574000, salePercent: 30 },
{ code: 'DTMDDH', salePrice: 574000, salePercent: 30 },
]


function CampaignLive(props) {
  const [productList, setProductList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [showBanner, setShowBanner] = useState(true);
  const [mainPageIndex, setMainPageIndex] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const codeChar = 'A';
  const codeLive = '20241107';
  let tDoDownloadAll = null;
  const { isLoading, mutate: getProductList } = useMutation(() => {
    return request.get('v1/product-meta');
  },
    {
      onSuccess: (response) => {
        setProductList(response.data);
        initData(response.data);
      },
    });
  useEffect(() => {
    getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const initData = (productList) => {
    const data = [];
    PRODUCTS.forEach(p => {
      const sku = p.code.toLowerCase();
      const item = productList.find(x => x.pSku === sku);
      if (item) {
        // const salePercent = 100 - Math.round(p.salePrice / item.price * 100);
        // if(salePercent >= 40){
        const newItem = {
          ...item,
          salePrice: p.salePrice,
          salePercent: p.salePercent,
        }
        if(!item.image && p.url){
          newItem.image = p.url;
        }
        data.push(newItem);
        // }
      }
    })
    setDataSource(data);
  }
  const handleImageLoaded = () => {
    if (isDownloading) {
      handleDownload();
      clearTimeout(tDoDownloadAll);
      const nextTick = Math.round(Math.random() * 1000 + 1000);
      tDoDownloadAll = setTimeout(() => { doDownloadAll(); }, nextTick);
    }
    console.log('image loaded');
  }
  const handleDownloadAll = async () => {
    if (isDownloading) {
      setIsDownloading(false);
    } else {
      setIsDownloading(true);
      setMainPageIndex(67);
      doDownloadAll();
    }
  }
  const doDownloadAll = async () => {
    if (mainPageIndex >= PRODUCTS.length) {
      setIsDownloading(false);
      return;
    }
    if (mainPageIndex < PRODUCTS.length - 1) {
      let ind = mainPageIndex + 1;
      const p = PRODUCTS[ind];
      if (!p) ind += 1;
      else {
        const sku = p.code.toLowerCase();
        const item = productList.find(x => x.pSku === sku);
        if (!item.image) {
          ind += 1;
        }
      }

      setMainPageIndex(ind);
    } else {
      setIsDownloading(false);
    }
    return;
  }
  const handleDownload = async () => {
    var node = document.querySelector('.productLiveBanner');
    const name = node.getAttribute('data-name');
    // const name = 'TEST';
    domtoimage.toBlob(node)
      .then(function (blob) {
        FileSaver.saveAs(blob, `${name}.png`);
      });
  }
  const handleShowBanner = () => {
    if (!showBanner) {
      setMainPageIndex(0);
    }
    setShowBanner(!showBanner);
  }
  const handleClickItem = (itemInd => {
    setMainPageIndex(itemInd);
    if (!showBanner) setShowBanner(true);
  })
  const renderProductDetail = (item, ind) => {
    // const sku = p.code.toLowerCase();
    // const item = productList.find(x => x.pSku === sku);
    // if (!item) return '';
    return (
      <Col sm={6} style={{ marginBottom: 16 }} key={item.pSku}>
        <Card hoverable cover={<img height={400} width="auto" alt={item.pName} src={item.image} />} onClick={() => handleClickItem(ind)}>
          <div style={{ height: 42 }}>{item.pName} - {item.pSku}</div>
          <div style={{ fontSize: 20 }}>{formatNumber(item.price)}{` => `}<b>{formatNumber(item.salePrice)}</b></div>
        </Card>
      </Col>
    )
  }
  const renderProductBanner = () => {
    if (mainPageIndex < 0) return '';
    // const p = PRODUCTS[mainPageIndex];
    // if (!p) return '';
    // const sku = p.code.toLowerCase();
    // const item = productList.find(x => x.pSku === sku);
    const item = dataSource[mainPageIndex];
    if (!item) return '';
    const orgPrice = item.price / 1000;
    const salePrice = item.salePrice / 1000;
    const sku = item.pSku.toLowerCase();
    const name = item.pName;
    const imageSrc = item.image ? item.image.replace('https://otina.n4u.vn/', 'https://otina-proxy.n4u.vn/') : '';
    return (
      <div className='productLiveBanner' data-percent={item.salePercent} data-name={`D${mainPageIndex + 1}-${item.salePercent}-${salePrice}-${codeLive}-${sku.toUpperCase()}`}>
        <img className='imageSrc' alt="MainImageLive" src={imageSrc} onLoad={handleImageLoaded} />
        <div className='imageFrame' style={{ backgroundImage: `url(${imageSrc})` }}></div>
        <div className='liveCode'>{codeChar}{mainPageIndex + 1}</div>
        <div className='priceArea'>
          <span className='orgPrice'>{`${orgPrice}K`}</span>
          <span> => </span>
          <span className='salePrice'>{salePrice}K</span>
        </div>
        <div className='name'>{name}</div>
      </div>
    )
  }
  return (
    <Spin spinning={isLoading}>
      <Row gutter={16}>
        <Col sm={12}>
          <h2>Live {codeLive}</h2>
        </Col>
        <Col sm={6}>
          <Button onClick={handleShowBanner}>{showBanner ? 'Hide' : 'Show'}</Button>
          {showBanner &&
            <Button onClick={handleDownloadAll}>{isDownloading ? `Downloading (${mainPageIndex + 1}/${PRODUCTS.length})` : `Download All`}</Button>
          }
        </Col>
      </Row>
      {showBanner && (<Row gutter={16}>
        <Col sm={2} align="right">
          <Button style={{ marginTop: 20 }} disabled={mainPageIndex < 1} onClick={() => setMainPageIndex(mainPageIndex - 1)}>Previous</Button>
          <Button onClick={handleDownload}>Download</Button>
        </Col>
        <Col sm={20}>
          <div>
            {renderProductBanner()}
          </div>
        </Col>
        <Col sm={2}>
          <Button style={{ marginTop: 20 }} disabled={mainPageIndex > PRODUCTS.length - 2} onClick={() => setMainPageIndex(mainPageIndex + 1)}>Next</Button>
        </Col>
      </Row>)}
      <Row gutter={16}>
        {dataSource.map(renderProductDetail)}
      </Row>
    </Spin>
  )
}

CampaignLive.propTypes = {
  dataSource: PropTypes.array,
  categoryList: PropTypes.array,
  loading: PropTypes.bool,
};

CampaignLive.defaultProps = {
  dataSource: [],
  categoryList: [],
  loading: false,
};

export default CampaignLive;
